.card {
  display: flex;
  flex-direction: column;
}

.horizontal {
  flex-direction: row;
}

.wrap {
  flex-wrap: wrap;
}
